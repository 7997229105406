<template>
  <el-drawer :title="title" :visible.sync="visible" :destroy-on-close="true">
    <div class="flex flex-direction" style="height:100%;">
      <el-card>
        <l-description :option="descOption" :data="descData">
          <template slot="assetState">
            <span v-if="dataInfo.assetState == 1">在建</span>
            <span v-else-if="dataInfo.assetState == 2">启用</span>
            <span v-else-if="dataInfo.assetState == 3">封存</span>
            <span v-else-if="dataInfo.assetState == 4">处置</span>
            <span v-else-if="dataInfo.assetState == 5">报废</span>
          </template>
          <template slot="yesOrNo" slot-scope="scope">
            {{ dataInfo[scope.prop] ? '是' : '否' }}
          </template>
        </l-description>
      </el-card>
      <el-card class="margin-top-sm flex-sub overflow">
        <json-viewer :value="jsonData" :expand-depth="2" copyable></json-viewer>
      </el-card>
    </div>
  </el-drawer>
</template>

<script>
import JsonViewer from 'vue-json-viewer'
export default {
  components: { JsonViewer },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      descOption: {
        size: 'medium ',
        column: 2,
        labelStyle: {
          width: '100px'
        }
      },
      descData: null
    }
  },
  mounted() {
    this.descData = [
      { label: '设备编号', text: this.dataInfo.deviceNo },
      { label: '上报时间', text: this.dataInfo.recordTime },
      { label: '消息ID', text: this.dataInfo.msgDesc },
      { label: '消息描述', text: this.dataInfo.msgDesc },
      { label: 'HEX报文', text: this.dataInfo.hexContent, span: 2 }
    ]
    this.jsonData = JSON.parse(this.dataInfo.jsonContent)
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    }
  }
}
</script>

<style lang="less">
.detail_area {
  height: 100%;
  textarea {
    height: 100%;
    background-color: rgb(30, 30, 30);
    color: rgb(220, 220, 220);
  }
}
</style>
>
